<template>
  <div>
    <!-- <WidgetsDropdown/> -->
    <CCard>
      <CCardBody>
        <div class="jumbotron">
          <h1>Selamat Datang, {{ username }}</h1>
        </div>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader>Formasi Pegawai</CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="9">
            <div role="group" class="form-group">
              <label class>Filter OPD</label>
              <v-select
                v-model="form"
                :options="optionsOPD"
                item-text="nama_opd"
                item-value="id_opd"
                label="nama_opd"
                :filterable="true"
                @search="onSearchOPD"
              ></v-select>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12">
            <CSpinner color="primary" class="spinner-center" v-if="loading"/>
            <organization-chart v-else :datasource="dsc" verticalLevel="1" @node-click="selectNode"></organization-chart>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OrganizationChart from 'vue-organization-chart'
import 'vue-organization-chart/dist/orgchart.css'
import VSelect from "vue-select";
import _ from "lodash";
export default {
  name: "Dashboard",
  components: {
    OrganizationChart,
    VSelect,
  },
  data() {
    return {
      loading: false,
      optionsOPD: [],
      form: {
        id_opd: "0303000000",
        nama_opd: "BADAN KEPEGAWAIAN DAN PENGEMBANGAN SUMBER DAYA MANUSIA"
      },
      ds: {
        'id': '2',
        'name': '0101000000',
        'title': 'SEKRETARIAT DAERAH',
        'children': [
          {
            'id': '3', 'name': '0101010000', 'title': 'ASISTEN SEKRETARIS DAERAH BIDANG PEMERINTAHAN DAN KESEJAHTERAAN RAKYAT',
            'children': [
              {
                'id': '30', 'name': '0101030100', 'title': 'BAGIAN ORGANISASI',
                'children': [
                  { 'id': '31', 'name': '0101030101', 'title': 'SUB BAGIAN KELEMBAGAAN DAN ANALISIS JABATAN' },
                  { 'id': '32', 'name': '0101030102', 'title': 'SUB BAGIAN PELAYANAN PUBLIK DAN TATALAKSANA' },
                  { 'id': '33', 'name': '0101030103', 'title': 'SUB BAGIAN KINERJA DAN REFORMASI BIROKRASI' },
                ]
              },
              {
                'id': '34', 'name': '0101030200', 'title': 'BAGIAN UMUM',
                'children': [
                  { 'id': '35', 'name': '0101030201', 'title': 'SUB BAGIAN TATA USAHA PIMPINAN, STAF AHLI DAN KEPEGAWAIAN' },
                  { 'id': '36', 'name': '0101030202', 'title': 'SUB BAGIAN KEUANGAN' },
                  { 'id': '37', 'name': '0101030203', 'title': 'SUB BAGIAN RUMAH TANGGA DAN PERLENGKAPAN' },
                ]
              },
              {
                'id': '38', 'name': '0101030300', 'title': 'BAGIAN PROTOKOL DAN KOMUNIKASI PIMPINAN',
                'children': [
                  { 'id': '39', 'name': '0101030301', 'title': 'SUB BAGIAN PROTOKOL' },
                  { 'id': '40', 'name': '0101030302', 'title': 'SUB BAGIAN KOMUNIKASI PIMPINAN' },
                  { 'id': '41', 'name': '0101030303', 'title': 'SUB BAGIAN DOKUMENTASI PIMPINAN' },
                ]
              },
            ]
          },
          {
            'id': '16', 'name': '0101020000', 'title': 'ASISTEN SEKRETARIS DAERAH BIDANG PEREKONOMIAN DAN PEMBANGUNAN',
            'children': [
              {
                'id': '17', 'name': '0101020100', 'title': 'BAGIAN PEREKONOMIAN DAN SUMBER DAYA ALAM',
                'children': [
                  { 'id': '18', 'name': '0101020101', 'title': 'SUB BAGIAN PEMBINAAN BUMD DAN BADAN LAYANAN UMUM DAERAH (BLUD)' },
                  { 'id': '19', 'name': '0101020102', 'title': 'SUB BAGIAN PEREKONOMIAN' },
                  { 'id': '20', 'name': '0101020103', 'title': 'SUB BAGIAN DOKUMENTASI PIMPINAN' },
                ]
              },
              { 'id': '21', 'name': '0101020200', 'title': 'BAGIAN ADMINISTRASI PEMBANGUNAN',
                'children': [
                  { 'id': '22', 'name': '0101020201', 'title': 'SUB BAGIAN PENYUSUNAN PROGRAM' },
                  { 'id': '23', 'name': '0101020202', 'title': 'SUB BAGIAN PENGENDALIAN PROGRAM' },
                  { 'id': '24', 'name': '0101020203', 'title': 'SUB BAGIAN EVALUASI DAN PELAPORAN' },
                ] },
              { 'id': '25', 'name': '0101020300', 'title': 'SUB BAGIAN SUMBER DAYA ALAM',
                'children': [
                  { 'id': '26', 'name': '0101020301', 'title': 'SUB BAGIAN PENGELOLAAN PENGADAAN BARANG DAN JASA' },
                  { 'id': '27', 'name': '0101020302', 'title': 'SUB BAGIAN PENGELOLAAN LAYANAN PENGADAAN SECARA ELEKTRONIK' },
                  { 'id': '28', 'name': '0101020303', 'title': 'SUB BAGIAN PEMBINAAN DAN ADVOKASI PENGADAAN BARANG DAN JASA' },
                ] },
            ]
          },
          {
            'id': '29', 'name': '0101030000', 'title': 'ASISTEN SEKRETARIS DAERAH BIDANG ADMINISTRASI UMUM',
            'children': [
              { 'id': '30', 'name': '0101030100', 'title': 'BAGIAN ORGANISASI',
                'children': [
                  { 'id': '31', 'name': '0101030101', 'title': 'SUB BAGIAN KELEMBAGAAN DAN ANALISIS JABATAN' },
                  { 'id': '32', 'name': '0101030102', 'title': 'SUB BAGIAN PELAYANAN PUBLIK DAN TATALAKSANA' },
                  { 'id': '33', 'name': '0101030103', 'title': 'SUB BAGIAN KINERJA DAN REFORMASI BIROKRASI' },
                ] },
              { 'id': '34', 'name': '0101030200', 'title': 'BAGIAN UMUM',
                'children': [
                  { 'id': '35', 'name': '0101030201', 'title': 'SUB BAGIAN TATA USAHA PIMPINAN, STAF AHLI DAN KEPEGAWAIAN' },
                  { 'id': '36', 'name': '0101030202', 'title': 'SUB BAGIAN KEUANGAN' },
                  { 'id': '37', 'name': '0101030203', 'title': 'SUB BAGIAN RUMAH TANGGA DAN PERLENGKAPAN' },
                ] },
              { 'id': '38', 'name': '0101030300', 'title': 'BAGIAN PROTOKOL DAN KOMUNIKASI PIMPINAN',
                'children': [
                  { 'id': '39', 'name': '0101030301', 'title': 'SUB BAGIAN PROTOKOL' },
                  { 'id': '40', 'name': '0101030302', 'title': 'SUB BAGIAN KOMUNIKASI PIMPINAN' },
                  { 'id': '41', 'name': '0101030303', 'title': 'SUB BAGIAN DOKUMENTASI PIMPINAN' },
                ] },
            ]
          },
          { 'id': '43', 'name': '0101040000', 'title': 'STAF AHLI BIDANG PEMERINTAHAN, HUKUM DAN KESEJAHTERAAN RAKYAT' },
          { 'id': '44', 'name': '0101050000', 'title': 'STAF AHLI BIDANG PEREKONOMIAN DAN PEMBANGUNAN' },
          { 'id': '45', 'name': '0101060000', 'title': 'STAF AHLI BIDANG SUMBER DAYA MANUSIA, KEUANGAN DAN ADMINISTRASI' },
        ]
      },
      dsc: {}
    };
  },
  mounted() {
    this.loadData();
    this.loadOPD();
  },
  watch: {
      "form.id_opd": function () {
        this.loadData();
      },
    },
  computed: {
    ...mapGetters("auth", {
      user: "user",
    }),
    username() {
      return this.user ? this.user.fullname : "-";
    },
  },
  methods: {
    async loadOPD() {
      this.optionsOPD = await this.$store.dispatch(
        "kualifikasi_jabatan_struktural/autocomplete_opd",
        "a"
      );
    },
    async loadData() {
      try {
        this.loading = true;
        let data = await this.$store.dispatch("formasi_jabatan/index", {
          data: this.form
        });
        this.dsc = data[0];
      } catch (ex) {
        this.dsc = {};
      } finally {
        this.loading = false;
      }
    },
    async onSearchOPD(search, loading) {
      loading(true);
      await this.searchOPD(search, this);
      loading(false);
    },
    searchOPD: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch(
        "kualifikasi_jabatan_struktural/autocomplete_opd",
        search
      );
      if (search) vm.optionsOPD = options;
    }, 300),
    selectNode(nodeData){
      alert(nodeData.name + ' : ' + nodeData.title)
    }
  }
};
</script>
<style scoped>
.spinner-center{
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
